import React, {useEffect, useRef} from 'react';
import {Stream} from "@cloudflare/stream-react";

const LeicaCloudflareVideo = ({paused}) => {
  const playerRef = useRef();

  function playVideo() {
    if (playerRef.current) {
      playerRef.current.play();
    }
  }

  function pauseVideo() {
    if (playerRef.current) {
      playerRef.current.pause();
    }
  }

  useEffect(() => {
    if (paused) {
      pauseVideo();
    } else {
      playVideo();
    }
  }, [paused]);

  return (
    <Stream
      streamRef={playerRef}
      src={`da50d5b35c09ca4b629ea38ef420fa17`}
      autoplay={true}
      controls={false}
      muted={true}
      loop={true}
      preload={`true`}
      responsive={true}
      onPlay={() => {
        console.log('Clouflare Stream onPlay triggered');
      }}
    />
  )
}

export default LeicaCloudflareVideo;
