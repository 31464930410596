import React, {useEffect, useRef} from 'react';
import YouTube from 'react-youtube';

const LeicaHlsVideo = ({paused}) => {
  const playerRef = useRef();

  const youTubeOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      mute: 1,
      mode: `opaque`,
      wmode: `transparent`,
      playlist: `EQWkrv-JxgQ`,
      autoplay: 1,        // Auto-play the video on load
      disablekb: 1,
      controls: 0,        // Hide pause/play buttons in player
      showinfo: 0,        // Hide the video title
      modestbranding: 1,  // Hide the Youtube Logo
      loop: 1,            // Run the video in a loop
      fs: 0,              // Hide the full screen button
      autohide: 1,         // Hide video controls when playing
      rel: 0,
      enablejsapi: 1
    },
  };

  const onYouTubePlayerReady = (e) => {
    // access to player in all event handlers via event.target
    e.target.mute();
    e.target.setPlaybackQuality('hd1080');
    e.target.playVideo();

    playerRef.current = e.target;
  }


  function playVideo() {
    if (playerRef.current) {
      playerRef.current.playVideo();
    }
  }

  function pauseVideo() {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
  }

  useEffect(() => {
    if (paused) {
      pauseVideo();
    } else {
      playVideo();
    }
  }, [paused]);

  return (
    <div style={{height: 0, paddingBottom: `56%`, position: `relative`}}>
      <div style={{
        position: `absolute`,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}>
        <YouTube
          videoId="EQWkrv-JxgQ"
          opts={youTubeOpts}
          style={{height: '100%', width: '100%'}}
          onReady={onYouTubePlayerReady}/>
      </div>
      <div style={{
        position: `absolute`,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: `none`
      }}></div>
    </div>
  )
}

export default LeicaHlsVideo;
