import React, {useEffect, useRef} from 'react';
import * as vidyardEmbed from '@vidyard/embed-code';

/**
 * Extract the video UUID from a Vidyard URL.
 */
const extractVideoId = (url) => {
  const reg = /(http(s?):\/\/)?[a-zA-Z0-9]+\.vidyard.com\/(?:share|watch)?(\/)?([a-zA-Z0-9_-]+)/;
  const matches = url.match(reg);
  return matches && matches[4] ? matches[4] : null;
};

const MyVidyard = ({videoUrl, paused = false, onPlay}) => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);

  const init = useRef(false);

  const isPlaying = useRef(!paused);

  // Init the player.
  useEffect(() => {
    console.log('paused', paused)
    const uuid = extractVideoId(videoUrl);

    // Don't proceed if there is no container.
    if (!uuid) {
      return;
    }

    // Don't proceed if there is no container.
    if (!containerRef.current) {
      return;
    }

    // We run it once if the player is not initialized.
    if (playerRef.current) {
      return;
    }

    console.log(`Init ${uuid}`)

    // Render the Vidyard player
    vidyardEmbed.api
      .renderPlayer({
        container: containerRef.current,
        uuid,
        loop: 1,
        autoplay: 1,
        muted: 1,
        preload: 'auto',
        hidden_controls: 1,
        hide_html5_playlist: 1,
        hide_playlist: 1,
        name_overlay: 0,
        embed_button: 0
      })
      .then((player) => {
        playerRef.current = player;

        console.log(`Vidyard player ${player.uuid} is loaded (${Date.now()}).`);

        // Pause player immediately if paused.
        if (paused) {
          setTimeout(() => {
            player.pause();
          }, 10);
        }

        // Attach the play event listener
        if (player) {
          player.on('play', () => {
            if (!init.current) {
              init.current = true;
              if (paused) {
                if (playerRef.current) {
                  playerRef.current.pause();
                  console.log(`Paused Vidyard player ${player.uuid} on init (${Date.now()}).`);
                }
                return;
              }
            }

            console.log(`Vidyard player ${player.uuid} is playing (${Date.now()}).`);
            isPlaying.current = true;
          });
          player.on('pause', () => {
            console.log(`Vidyard player ${player.uuid} is paused (${Date.now()}).`);
            isPlaying.current = false;
          });

          vidyardEmbed.api.progressEvents(({chapter, event, player}) => {
            if (typeof onPlay === 'function') {
              onPlay();
            }
          }, [0], player);
        }
      })
      .catch((e) => {
        console.error(`Error loading Vidyard player ${uuid}: ${e.message}`);
      });
  }, [onPlay, paused, videoUrl]);

  // Pause / resume.
  useEffect(() => {
    console.log('isPlaying.current', isPlaying.current)
    if (playerRef.current) {
      if (isPlaying.current && paused) {
        console.log(`Going to pause Vidyard player ${playerRef.current.uuid}`);
        playerRef.current.pause();
      }
      else if (!isPlaying.current && !paused) {
        console.log(`Going to resume Vidyard player ${playerRef.current.uuid}`);
        playerRef.current.resume();
      }
    }
  }, [paused]);

  return <div className={`vidyard-player-wrapper`} ref={containerRef}></div>;
};

export default MyVidyard;
